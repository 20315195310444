import React              from 'react';
import { Link, navigate } from 'gatsby';

// import styles
import { ErrorPageElement } from '../styles/Blog404';

// import components
import PageMeta  from '../components/elements/PageMeta';
import PhoneIcon from '../components/elements/icons/PhoneIcon';
import Seo       from '../components/common/Seo';

const NotFoundPage = () => (
  <>
    <Seo title="500: Internal server error" description="500: Internal server error" lang="de" />
    <PageMeta type="page" text="500" />
    <div className="container">
      <ErrorPageElement>
        <div className="error-box">
          <h2 className="error-box__pre-title">Oops!</h2>
          <h1 className="error-box__title">Something has gone wrong!</h1>
          <p className="error-box__text error-box__text--small">We’re unable to fulfill your request. Rest assured we have been notified and are looking into the issue. Please refresh your browser. If the error continues,</p>
          <p className="error-box__text error-box__text--small">Please contact our support team.</p>
          <button className="error-box__btn"
                  type="button">
            <span className="error-box__btn__icon">
              <PhoneIcon className="error-box__btn__icon__item" color="#724B9E" />
            </span>
            Contact support
          </button>
          <div className="error-box__links">
            <span className="error-box__links__item"
                  role="presentation"
                  onClick={ () => navigate( -1 ) }>Go back</span>
            <Link className="error-box__links__item" to="/">Go to Home</Link>
          </div>
        </div>
      </ErrorPageElement>
    </div>
  </>
);
export default NotFoundPage;